import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Sticky from 'react-stickynode';
import Navbar from '../containers/Crypto/Navbar';
import Footer from '../containers/Crypto/Footer';
import BlogBanner from '../containers/Crypto/Blog/BlogBanner';
import Text from 'reusecore/src/elements/Text';
import Box from 'reusecore/src/elements/Box';
import { ThemeProvider } from 'styled-components';
import { cryptoTheme } from 'common/src/theme/crypto';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Crypto/crypto.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
//import Layout from '../components/Layout'
//import Content, { HTMLContent } from '../components/Content'

export const PrivacyPolicyTemplate = ({ data, row, contactForm }) => {
  const { markdownRemark: content } = data;

  return (
    <ThemeProvider theme={cryptoTheme}>
      <Fragment>
        <SEO title="Oddshero | Privacy Policy" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BlogBanner pageTitle={content.frontmatter.title} />
          <Box {...row}>
            <Box
              {...contactForm}
              style={{
                marginBottom: 350,
                marginLeft: 40,
                marginRight: 40,
                maxWidth: '50em',
              }}
            >
              <Text
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.frontmatter.description,
                    }}
                  />
                }
              />
            </Box>
          </Box>
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

PrivacyPolicyTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

PrivacyPolicyTemplate.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['40px', '40px', '40px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1],
  },
  button: {
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${4}`,
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    content: 'Get The Latest PayBear Updates',
    fontSize: ['20px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center'],
  },

  description: {
    content: 'And be the first to know when our crowdsale launches!.',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
};

export default PrivacyPolicyTemplate;

export const pageQueryPp = graphql`
  query MyQueryPp {
    markdownRemark(
      frontmatter: { templateKey: { eq: "privacy-policy-page" } }
    ) {
      id
      frontmatter {
        templateKey
        title
        description
      }
      fields {
        slug
      }
    }
  }
`;
